export const onInitialClientRender = () => {
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  }
};

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(location.search);
    const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
    let foundParams = {};

    utmParams.forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        foundParams[param] = value;
      }
    });

    if (Object.keys(foundParams).length > 0) {
      sessionStorage.setItem("utmParams", JSON.stringify(foundParams));
    }
  }
};